/*
	UIkit customization
 */

UIkit.modal.i18n = {
  ok: 'Aceptar',
  cancel: 'Cancelar',
};

UIkitUploadI18n = {
  invalidMime: 'Por favor selecciona un tipo de archivo no válido.',
  invalidName:
    'El archivo seleccionado no es válido, por favor selecciona otro.',
  invalidSize:
    'El archivo seleccionado es demasiado grande, por favor selecciona otro de como mucho 2MB.',
};

function customAlert(title, message) {
  return UIkit.modal.alert(
    '<strong>' + title + '</strong><p>' + message + '</p>',
    { center: true, stack: true },
  );
}
function customConfirm(title, message) {
  return UIkit.modal.confirm(
    '<strong>' + title + '</strong><p>' + message + '</p>',
    { center: true },
  );
}

function urlParams(params, separate = true) {
  const query = Object.entries(params)
    .map((pair) => pair.join('='))
    .join('&');
  return (separate ? '?' : '') + query;
}

/*
	Axios configuration
 */

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Get Query params
 */

function urlQueryParams(key) {
  const url = new URL(location.href);
  return url.searchParams ? url.searchParams.get(key) : null;
}

/**
 * Undefined links
 */
(function undefinedLinks() {
  $('a[href="#undefined"]').on('click', function (e) {
    e.preventDefault();
    UIkit.modal.alert('¿A dónde ir?');
  });
})();

/*
	User links
 */
(function userLinks() {
  $('a[href="#login"]').on('click', (e) => {
    e.preventDefault();
    domestiko.$emit('form.login');
  });
  $('a[href="#register"]').on('click', (e) => {
    e.preventDefault();
    domestiko.$emit('form.register');
  });
})();

/*
	Sticky menú
 */

(function stickyMenu() {
  const $menu = $('#main-menu');
  function update(e) {
    $menu.toggleClass('uk-active', window.pageYOffset > 10);
  }
  $(document).on('scroll resize', update);
})();

/*
	Hover de menú superior
 */

(function hoverMenuDesktop() {
  $('#main-menu a + .uk-navbar-dropdown').each((i, el) => {
    $(el).on('show', function (e) {
      $(this).prev().addClass('hover');
    });
    $(el).on('hide', function (e) {
      $(this).prev().removeClass('hover');
    });
  });
})();

/*
	Offcanvas menú
 */

//TODO: refactorizar a n levels
(function offcanvasMenu() {
  let $menu = $('.uk-offcanvas-menu');

  //abrimos y cerramos secciones
  $menu.find('li.supermenu > a').on('click', (e) => {
    if (e.target.href) {
      return;
    }
    e.preventDefault();
    $menu.addClass('-sectors');
    $menu.parent().addClass('-clean');
    $(e.target).closest('.supermenu').addClass('active');
  });
  $menu.find('li.supermenu > a + .submenu button.back').on('click', (e) => {
    e.preventDefault();
    $menu.removeClass('-sectors');
    $menu.parent().removeClass('-clean');
    $(e.target).closest('.supermenu').removeClass('active');
  });

  //abrimos y cerramos sectores
  $menu.find('li.supermenu > a + .submenu a').on('click', (e) => {
    if (e.target.href) {
      return;
    }
    if ($(e.target).hasClass('final')) return true;
    e.preventDefault();
    $menu.addClass('-activities');
  });
  $menu
    .find('li.supermenu > a + .submenu + .submenu button.back')
    .on('click', (e) => {
      $menu.removeClass('-activities');
    });

  $('#offcanvas').on('hidden', (e) => {
    $menu.removeClass('-sectors').removeClass('-activities');
  });
})();

/*
	Menú de búsqueda
 */

(function searchBarToggle() {
  $('a[href="#search-bar"]').on('click', function (e) {
    e.preventDefault();
    $(this).toggleClass('-open');
    $('body').toggleClass('-search-bar');
  });
})();

/**
 * JS links
 */
(function undefinedLinks() {
  $('[data-href]').on('click', function (e) {
    if (e.target.href) {
      return true;
    }
    e.preventDefault();
    document.location = e.currentTarget.dataset.href;
  });
})();

/*
    Read More
 */
(function readMore() {
  if ($('body').width() >= 920) {
    $('.readmore').removeClass('readmore');
    return;
  }
  $('<button class="uk-button-readmore">Leer más</button>')
    .on('click', function (e) {
      $(this).parent().removeClass('readmore');
      $(this).remove();
    })
    .appendTo('.readmore');
})();

/*
	Contact form button
 */
(function profilesContactFormButton() {
  $('.profiles-contact-button').on('click', function (e) {
    e.preventDefault();
    const profile = $(this).data();
    domestiko.$emit('form.contact', profile);
  });
})();

/*
	Job location page
 */
(function jobLocationSitemap() {
  if (location.hash) {
    const el = $('.sitemap-accordion').find(location.hash);
    if (el.length) {
      UIkit.accordion(el.parent()).toggle(el);
    }
  }

  //Select menu
  $('.sitemap-menu select').on('change', function (e) {
    location.hash = e.target.value;
    e.target.value = '';
  });
})();

/*
	Google Custom Search Ads
 */
function googleCSA() {
  let ads = [];
  let options = {
    hl: 'es',
    adPage: 1,
    personalizedAds: 1,
  };
  $('.publi.csa').each((i, e) => {
    const $e = $(e);
    const config = $e.data('config');
    options = Object.assign({}, options, config);
    let id = Math.random().toString(36).split('.')[1];
    $e.attr('id', id);
    ads.push({ container: id });
  });

  if (!ads.length) {
    return;
  }

  //console.log('CSA', options, ...ads);
  $(function () {
    _googCsa('ads', options, ...ads);
  });
}

(function googleAds() {
  let observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting !== true) {
          return;
        }
        const ad = entry.target.querySelector('.lazyad');
        if (ad) {
          ad.classList.add('adsbygoogle');
          ad.classList.remove('lazyad');
          (adsbygoogle = window.adsbygoogle || []).push({});
        }
      });
    },
    {
      threshold: 0,
    },
  );

  document.querySelectorAll('.publi.content').forEach((e) => {
    const ad = e.querySelector('.adsbygoogle');
    ad.classList.add('lazyad');
    ad.classList.remove('adsbygoogle');
    observer.observe(e);
  });
})();

(function revocationMessage() {
  const observer = new MutationObserver(() => {
    document.querySelector('.google-revocation-link-placeholder')?.remove();
  });
  observer.observe(document.body, {
    childList: true,
  });
})();

(function clickioAdsHomeLink() {
  const observer = new MutationObserver(() => {
    document.querySelector('#lx_715476 .lx_home_button')?.remove();
  });
  observer.observe(document.body, { childList: true });
})();
